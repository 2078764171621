import React from "react";
import { Link } from "gatsby";

import styles from "./index.module.css";

export default () => (
  <div className={styles.container}>
    <div>
      <p className={styles.mail}>
        <h1>
          Hello, I am Jann Driessen,
          <br />a software developer.
        </h1>
        <a href="&#0109;&#097;&#0105;&#0108;&#0116;&#0111;&#058;&#0104;&#0101;&#0108;&#0108;&#0111;&#064;&#0106;&#097;&#0110;&#0110;&#0100;&#0114;&#0105;&#0101;&#0115;&#0115;&#0101;&#0110;&#046;&#099;&#0111;&#0109;">
          &#0104;&#0101;&#0108;&#0108;&#0111;&#064;&#0106;&#097;&#0110;&#0110;&#0100;&#0114;&#0105;&#0101;&#0115;&#0115;&#0101;&#0110;&#046;&#099;&#0111;&#0109;
        </a>
      </p>
      <p className={styles.social}>
        <a href="https://de.linkedin.com/in/jann-driessen-433bb475/en">
          LinkedIn
        </a>
        <a href="https://twitter.com/janndriessen">Twitter</a>
        <a href="https://www.xing.com/profile/Jann_Driessen">Xing</a>
      </p>
      <p className={styles.text}>
        10+ years experience in app development. Created apps for startups as
        well as enterprises and big brands. I'm confident in the whole process
        from concept, design to programming, and release management or even team
        building. Some of my clients include Audi, Connox, Freeletics,
        HUK-COBURG, Lufthansa, Mayze, Vaillant, Vorwerk.
      </p>
    </div>
    <div className={styles.footer}>
      <Link to="/imprint">Imprint</Link>
    </div>
  </div>
);
